import { StrictMode } from "react";

import Router from "../Router";

export default function InitialiserWithStrictMode() {
  return (
    <StrictMode>
      <Initialiser />
    </StrictMode>
  );
}

function Initialiser() {
  return <Router />;
}
