import { Method } from "axios";

export interface Endpoint {
  url: string;
  method: Method;
}

// Base URLs

const BASE_URL = process.env.REACT_APP_API_BASE_URL!;
const API_URL = `${BASE_URL}/v1`;

// Public endpoints

export const INDEX_CALCULATORS: Endpoint = { url: `${API_URL}/calculators`, method: "get" };
export const SHOW_CALCULATOR: Endpoint = { url: `${API_URL}/calculators/[calculatorId]`, method: "get" };
export const CREATE_CALCULATION: Endpoint = {
  url: `${API_URL}/calculators/[calculatorId]/calculations`,
  method: "post",
};
