import { useCallback } from "react";
import axios from "axios";

import { Endpoint } from "../constants/endpoints.constants";
import { substituteValuesIntoPlaceholders } from "../helpers/url.helpers";

export function usePublicApiSynchronously() {
  const send = useCallback<
    ({
      endpoint,
      options,
    }: {
      endpoint: Endpoint;
      options?: { params?: any; data?: any; urlPlaceholderValues?: [{ name: string; value: string }] };
    }) => Promise<any>
  >(({ endpoint, options }) => {
    const abort = axios.CancelToken.source();
    const abortRequestTimeoutId = setTimeout(() => abort.cancel("Network Error"), 10000);

    const axiosConfiguration = Object.fromEntries(
      Object.entries({
        url: substituteValuesIntoPlaceholders(endpoint.url, options?.urlPlaceholderValues),
        method: endpoint.method,
        headers: { "Content-Type": "application/json" },
        params: options?.params,
        data: options?.data,
        timeout: 10000,
        cancelToken: abort.token,
      }).filter(([_, value]) => value !== undefined)
    );

    return axios(axiosConfiguration)
      .then((response) => {
        clearTimeout(abortRequestTimeoutId);
        return response;
      })
      .catch((error) => {
        clearTimeout(abortRequestTimeoutId);
        return Promise.reject(error);
      });
  }, []);

  return { send };
}
