import { useEffect } from "react";
import lottie from "lottie-web";

import loadingAnimation from "../../../assets/lottie/loading.json";

export default function LoadingIndicator({ styles }: { styles: string }) {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#loading-indicator-container")!,
      animationData: loadingAnimation,
    });
  }, []);

  return <div className={styles} id="loading-indicator-container" />;
}
