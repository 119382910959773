import { useMemo } from "react";
import { RefreshIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";

// import NHS from "../../../assets/svg/NHS.svg";

export default function Results() {
  let navigate = useNavigate();

  const value = useMemo(() => {
    return window.location.search.substring(3);
  }, []);

  return (
    <>
      <div className="bg-white">
        <div className="max-w-3xl mx-auto py-10 sm:py-16 px-8">
          <div className="flex space-x-3.5 sm:space-x-5 items-center">
            {/* <img className="h-7 sm:h-10" src={NHS} alt="NHS logo" /> */}
            <h1 className="text-2xl sm:text-4xl font-bold tracking-tight text-gray-900">
              Carbon Calculator
            </h1>
          </div>

          <div className="border-t border-gray-200 my-8 sm:my-10" />

          <h1 className="text-green-500 text-6xl sm:text-8xl tracking-tight text-center font-extrabold italic">
            {parseInt(value)/1000.0}
            <span className="text-green-400 ml-1 text-xl sm:text-3xl align-baseline">
              kg
            </span>
          </h1>

          <div className="mt-8 sm:mt-10 flex justify-center">
            <button
              type="button"
              onClick={() => navigate("/")}
              className="inline-flex items-center rounded-xl border border-transparent bg-nhsblue px-4 py-2 sm:px-6 sm:py-3 text-sm sm:text-base font-medium text-white shadow-sm hover:bg-nhsdarkblue focus:outline-none focus:ring-2 focus:ring-nhsblue focus:ring-offset-2"
            >
              <RefreshIcon
                className="-ml-1 mr-2 sm:mr-3 h-4 w-4 sm:h-5 sm:w-5"
                aria-hidden="true"
              />
              Try again
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
