import { BrowserRouter, Routes, Route } from "react-router-dom";

import RouteAuthenticator from "../RouteAuthenticator";
import NotFound from "../../visual/NotFound";
import Calculator from "../../visual/Calculator";
import Results from "../../visual/Results";

export default function RouterWithBrowserRouter() {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}

function Router() {
  return (
    <Routes>
      <Route path="admin" element={<RouteAuthenticator />}>
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="results" element={<Results />} />
      <Route path="" element={<Calculator />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
