import { useState, useCallback, useEffect, Fragment } from "react";
import { RadioGroup, Switch, Listbox, Transition } from "@headlessui/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { object, number } from "yup";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";

// import NHS from "../../../assets/svg/NHS.svg";
import LoadingIndicator from "../shared/LoadingIndicator";
import ErrorModal from "../shared/ErrorModal";
import { usePublicApiSynchronously } from "../../../hooks/use-public-api-synchronously.hook";
import {
  INDEX_CALCULATORS,
  SHOW_CALCULATOR,
  CREATE_CALCULATION,
} from "../../../constants/endpoints.constants";

export default function Calculator() {
  let navigate = useNavigate();
  const { send } = usePublicApiSynchronously();

  const [areCalculatorsLoading, setAreCalculatorsLoading] = useState(true);
  const [isCalculatorLoading, setIsCalculatorLoading] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [calculatorId, setCalculatorId] = useState("");
  const [calculator, setCalculator] = useState({} as any);
  const [calculators, setCalculators] = useState([] as any[]);

  const loadForm = useCallback(
    (calculatorId: string) => {
      setIsCalculatorLoading(true);
      setCalculatorId(calculatorId);

      send({
        endpoint: SHOW_CALCULATOR,
        options: {
          urlPlaceholderValues: [{ name: "calculatorId", value: calculatorId }],
        },
      })
        .then((response) => {
          setCalculator(response.data.calculator);
        })
        .catch((error) => {
          setTimeout(() => {
            setIsErrorModalOpen(true);
          }, 500);
        })
        .then(() => setIsCalculatorLoading(false));
    },
    [send]
  );

  useEffect(() => {
    send({
      endpoint: INDEX_CALCULATORS,
    })
      .then((response) => {
        const responseCalculators = response.data.calculators;
        setCalculators(response.data.calculators);
        console.log(responseCalculators);
        if (responseCalculators.length === 1) {
          loadForm(responseCalculators[0].slug);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          setIsErrorModalOpen(true);
        }, 500);
      })
      .then(() => {
        setAreCalculatorsLoading(false);
      });
  }, [send, loadForm]);

  return (
    <>
      <div className="bg-white">
        <div className="max-w-3xl mx-auto pb-10 sm:pb-16 px-8">
          <div className="flex flex-col">
            <img
              className="w-full"
              src="./header.png"
              alt="ct carbon calculator"
            />
            <div className="flex space-x-3.5 sm:space-x-5 items-center mt-4">
              {/* <img className="h-7 sm:h-10" src={NHS} alt="NHS logo" /> */}
              <h1 className="text-2xl sm:text-4xl font-bold tracking-tight text-gray-900">
                Carbon Calculator
              </h1>
            </div>
          </div>

          <div className="border-t border-gray-200 mt-8 mb-7 sm:mt-10 sm:mb-8" />

          {areCalculatorsLoading ? (
            <LoadingIndicator styles="w-64 h-64 m-auto" />
          ) : (
            <>
              {calculators.length > 0 ? (
                <>
                  <div className="flex items-center space-x-3 sm:space-x-4">
                    <div className="h-7 w-7 sm:h-8 sm:w-8 rounded-full bg-nhsblue flex items-center justify-center shrink-0">
                      <h6 className="text-white text-base sm:text-lg font-bold">
                        1
                      </h6>
                    </div>
                    <h6 className="text-lg text-nhsblue font-medium">
                      Choose your calculator
                    </h6>
                  </div>

                  <div className="mt-6">
                    <RadioGroup value={calculatorId} onChange={loadForm}>
                      <RadioGroup.Label className="sr-only">
                        Calculator
                      </RadioGroup.Label>
                      <div className="space-y-3">
                        {calculators.map((calculator) => (
                          <RadioGroup.Option
                            key={calculator.slug}
                            value={calculator.slug}
                            disabled={isCalculatorLoading}
                            className={({ checked }) =>
                              `border-nhsblue border-2 ${
                                checked
                                  ? "bg-nhsblue text-white"
                                  : "bg-white hover:bg-nhslightblue"
                              }
                    relative flex cursor-pointer rounded-xl px-6 py-5`
                            }
                          >
                            {({ checked }) => (
                              <>
                                <div className="flex w-full items-center justify-between space-x-4">
                                  <div className="flex items-center">
                                    <div className="text-sm">
                                      <RadioGroup.Label
                                        as="p"
                                        className={`font-medium text-base ${
                                          checked
                                            ? "text-white"
                                            : "text-gray-700"
                                        }`}
                                      >
                                        {calculator.name}
                                      </RadioGroup.Label>
                                      <RadioGroup.Description
                                        as="span"
                                        className={`inline text-sm ${
                                          checked
                                            ? "text-white"
                                            : "text-gray-500"
                                        }`}
                                      >
                                        <span>{calculator.description}</span>
                                      </RadioGroup.Description>
                                    </div>
                                  </div>
                                  {checked && (
                                    <div className="shrink-0 text-white">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="w-8 h-8"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>

                  {calculatorId ? (
                    <>
                      <div className="flex items-center space-x-3 sm:space-x-4 mt-12">
                        <div className="h-7 w-7 sm:h-8 sm:w-8 rounded-full bg-nhsblue flex items-center justify-center shrink-0">
                          <h6 className="text-white text-base sm:text-lg font-bold">
                            2
                          </h6>
                        </div>
                        <h6 className="text-lg text-nhsblue font-medium">
                          Enter your details
                        </h6>
                      </div>

                      <div className="border-2 border-nhsblue rounded-xl sm:py-2 px-6 sm:px-8 flex-1 mt-6">
                        {isCalculatorLoading ? (
                          <LoadingIndicator styles="w-64 h-64 m-auto" />
                        ) : (
                          <>
                            {calculator ? (
                              <Formik
                                initialValues={(() => {
                                  const publicIdsWithInitialValues =
                                    calculator.user_inputs.map(
                                      (userInput: any) => {
                                        let initialValue;

                                        switch (userInput.format.type) {
                                          case "boolean":
                                            initialValue = false;
                                            break;
                                          case "radio":
                                            initialValue =
                                              userInput.format!.values![0]
                                                .value;
                                            break;
                                          default:
                                            initialValue = "";
                                            break;
                                        }

                                        return {
                                          publicId: userInput.slug,
                                          initialValue: initialValue,
                                        };
                                      }
                                    );

                                  const initialValues: {
                                    [key: string]:
                                      | number
                                      | boolean
                                      | string
                                      | string[];
                                  } = {};
                                  for (
                                    let i = 0;
                                    i < publicIdsWithInitialValues.length;
                                    i++
                                  ) {
                                    initialValues[
                                      publicIdsWithInitialValues[i].publicId
                                    ] =
                                      publicIdsWithInitialValues[
                                        i
                                      ].initialValue;
                                  }

                                  initialValues.location =
                                    calculator.locations[0].slug;
                                  initialValues.regions = [];

                                  return initialValues;
                                })()}
                                validationSchema={(() => {
                                  const publicIdsWithValidationSchema =
                                    calculator.user_inputs.map(
                                      (userInput: any) => {
                                        let validationSchema;

                                        switch (userInput.format.type) {
                                          case "number":
                                            validationSchema = number()
                                              .typeError(
                                                "Please enter a number."
                                              )
                                              .integer(
                                                "Please enter a whole number."
                                              )
                                              .min(
                                                userInput.format.minimum!,
                                                `This must be greater or equal to ${userInput
                                                  .format.minimum!}.`
                                              )
                                              .max(
                                                userInput.format.maximum!,
                                                `This must be lesser or equal to ${userInput
                                                  .format.maximum!}.`
                                              );
                                            break;
                                          case "decimal":
                                            validationSchema = number()
                                              .typeError(
                                                "Please enter a number."
                                              )
                                              .min(
                                                userInput.format.minimum!,
                                                `This must be greater or equal to ${userInput
                                                  .format.minimum!}.`
                                              )
                                              .max(
                                                userInput.format.maximum!,
                                                `This must be lesser or equal to ${userInput
                                                  .format.maximum!}.`
                                              );
                                            break;
                                          default:
                                            validationSchema = undefined;
                                            break;
                                        }

                                        return {
                                          publicId: userInput.slug,
                                          validationSchema: validationSchema,
                                        };
                                      }
                                    );

                                  const validationSchema: {
                                    [key: string]: any;
                                  } = {};
                                  for (
                                    let i = 0;
                                    i < publicIdsWithValidationSchema.length;
                                    i++
                                  ) {
                                    if (
                                      publicIdsWithValidationSchema[i]
                                        .validationSchema
                                    ) {
                                      validationSchema[
                                        publicIdsWithValidationSchema[
                                          i
                                        ].publicId
                                      ] =
                                        publicIdsWithValidationSchema[
                                          i
                                        ].validationSchema;
                                    }
                                  }

                                  return object().shape(validationSchema);
                                })()}
                                onSubmit={(
                                  values,
                                  { setSubmitting, setFieldError }
                                ) => {
                                  console.log(values);

                                  if (
                                    values["age"] === "" &&
                                    values["weight"] === "" &&
                                    values["dose_length_product_dlp"] === ""
                                  ) {
                                    const message =
                                      "Please input either an age, weight or dose length product.";
                                    setFieldError("age", message);
                                    setFieldError("weight", message);
                                    setFieldError(
                                      "dose_length_product_dlp",
                                      message
                                    );

                                    setSubmitting(false);
                                    return;
                                  }

                                  if (
                                    (values["regions"] as string[]).length === 0
                                  ) {
                                    const message =
                                      "Please select at least one body region.";
                                    setFieldError("regions", message);
                                    setSubmitting(false);
                                    return;
                                  }

                                  for (const name of [
                                    "age",
                                    "weight",
                                    "dose_length_product_dlp",
                                  ]) {
                                    if (values[name] === "") {
                                      values[name] = 0;
                                    }
                                  }

                                  const userInputs = Object.keys(values)
                                    .filter(
                                      (key) =>
                                        key !== "location" && key !== "regions"
                                    )
                                    .reduce(
                                      (current, key) =>
                                        Object.assign(current, {
                                          [key]: values[key],
                                        }),
                                      {}
                                    );

                                  console.log(userInputs);
                                  send({
                                    endpoint: CREATE_CALCULATION,
                                    options: {
                                      urlPlaceholderValues: [
                                        {
                                          name: "calculatorId",
                                          value: calculatorId,
                                        },
                                      ],
                                      data: {
                                        calculation: {
                                          user_inputs: userInputs,
                                          location: values.location,
                                          regions: values.regions,
                                        },
                                      },
                                    },
                                  })
                                    .then((response) => {
                                      setSubmitting(false);

                                      if (response.data.result) {
                                        navigate(
                                          `/results?v=${parseFloat(
                                            response.data.result
                                          ).toFixed(2)}`
                                        );
                                      } else {
                                        setTimeout(() => {
                                          setIsErrorModalOpen(true);
                                        }, 500);
                                      }
                                    })
                                    .catch((error) => {
                                      setSubmitting(false);

                                      setTimeout(() => {
                                        setIsErrorModalOpen(true);
                                      }, 500);
                                    });
                                }}
                              >
                                {({
                                  handleSubmit,
                                  isSubmitting,
                                  errors,
                                  touched,
                                  isValid,
                                  values,
                                  setValues,
                                  handleChange,
                                }) => (
                                  <Form className="divide-y divide-gray-200">
                                    {calculator.user_inputs.map(
                                      (userInput: any) => {
                                        return (
                                          <div
                                            className="py-6"
                                            key={userInput.slug}
                                          >
                                            {(() => {
                                              switch (userInput.format.type) {
                                                case "number":
                                                  return (
                                                    <div>
                                                      <label
                                                        htmlFor={userInput.slug}
                                                        className="block text-sm font-medium text-gray-700"
                                                      >
                                                        {userInput.title}
                                                      </label>
                                                      <div className="relative mt-1.5 rounded-md shadow-sm">
                                                        <Field
                                                          type="text"
                                                          inputMode="numeric"
                                                          id={userInput.slug}
                                                          name={userInput.slug}
                                                          className="block w-full rounded-md border-gray-200 pl-4 pr-12 focus:border-nhsblue sm:text-sm"
                                                          placeholder="0"
                                                          // @ts-ignore
                                                          defaultValue={
                                                            values[
                                                              userInput.slug
                                                            ]
                                                          }
                                                        />
                                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                          <span className="text-gray-500 sm:text-sm">
                                                            {
                                                              userInput.format
                                                                .unit
                                                            }
                                                          </span>
                                                        </div>
                                                      </div>
                                                      {errors[userInput.slug] &&
                                                      touched[
                                                        userInput.slug
                                                      ] ? (
                                                        <ErrorMessage
                                                          name={userInput.slug}
                                                          component="div"
                                                          className="mt-2 text-sm font-medium text-red-500"
                                                        />
                                                      ) : (
                                                        <p className="mt-2 text-sm text-gray-500">
                                                          {
                                                            userInput.description
                                                          }
                                                        </p>
                                                      )}
                                                    </div>
                                                  );
                                                case "decimal":
                                                  return (
                                                    <div>
                                                      <label
                                                        htmlFor={userInput.slug}
                                                        className="block text-sm font-medium text-gray-700"
                                                      >
                                                        {userInput.title}
                                                      </label>
                                                      <div className="relative mt-1.5 rounded-md shadow-sm">
                                                        <Field
                                                          type="text"
                                                          inputmode="decimal"
                                                          id={userInput.slug}
                                                          name={userInput.slug}
                                                          className="block w-full rounded-md border-gray-200 pl-4 pr-12 focus:border-nhsblue sm:text-sm"
                                                          placeholder={
                                                            userInput.slug ===
                                                            "age"
                                                              ? "0"
                                                              : "0.0"
                                                          }
                                                          // @ts-ignore
                                                          defaultValue={
                                                            values[
                                                              userInput.slug
                                                            ]
                                                          }
                                                        />
                                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                          <span className="text-gray-500 sm:text-sm">
                                                            {
                                                              userInput.format
                                                                .unit
                                                            }
                                                          </span>
                                                        </div>
                                                      </div>
                                                      {errors[userInput.slug] &&
                                                      touched[
                                                        userInput.slug
                                                      ] ? (
                                                        <ErrorMessage
                                                          name={userInput.slug}
                                                          component="div"
                                                          className="mt-2 text-sm font-medium text-red-500"
                                                        />
                                                      ) : (
                                                        <p className="mt-2 text-sm text-gray-500">
                                                          {
                                                            userInput.description
                                                          }
                                                        </p>
                                                      )}
                                                    </div>
                                                  );
                                                case "boolean":
                                                  return (
                                                    <div className="flex items-center justify-between space-x-6">
                                                      <div>
                                                        <label
                                                          htmlFor={
                                                            userInput.slug
                                                          }
                                                          className="text-sm font-medium text-gray-700"
                                                        >
                                                          {userInput.title}
                                                        </label>
                                                        <p className="mt-0.5 text-sm text-gray-500">
                                                          {
                                                            userInput.description
                                                          }
                                                        </p>
                                                      </div>
                                                      <Switch
                                                        // @ts-ignore
                                                        checked={
                                                          values[userInput.slug]
                                                        }
                                                        id={userInput.slug}
                                                        onChange={(
                                                          value: boolean
                                                        ) => {
                                                          const newValues = {
                                                            ...values,
                                                          };
                                                          newValues[
                                                            userInput.slug
                                                          ] = value;

                                                          setValues(newValues);
                                                        }}
                                                        className={`${
                                                          values[userInput.slug]
                                                            ? "bg-nhsblue"
                                                            : "bg-gray-200"
                                                        } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out`}
                                                      >
                                                        <span className="sr-only">
                                                          {userInput.title}
                                                        </span>
                                                        <span
                                                          className={`${
                                                            values[
                                                              userInput.slug
                                                            ]
                                                              ? "translate-x-5"
                                                              : "translate-x-0"
                                                          } pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow transition duration-200 ease-in-out`}
                                                        >
                                                          <span
                                                            className={`${
                                                              values[
                                                                userInput.slug
                                                              ]
                                                                ? "opacity-0 ease-out duration-100"
                                                                : "opacity-100 ease-in duration-200"
                                                            } absolute inset-0 flex h-full w-full items-center justify-center transition-opacity`}
                                                            aria-hidden="true"
                                                          >
                                                            <svg
                                                              className="h-3 w-3 text-gray-400"
                                                              fill="none"
                                                              viewBox="0 0 12 12"
                                                            >
                                                              <path
                                                                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                              />
                                                            </svg>
                                                          </span>
                                                          <span
                                                            className={`${
                                                              values[
                                                                userInput.slug
                                                              ]
                                                                ? "opacity-100 ease-in duration-200"
                                                                : "opacity-0 ease-out duration-100"
                                                            } absolute inset-0 flex h-full w-full items-center justify-center transition-opacity`}
                                                            aria-hidden="true"
                                                          >
                                                            <svg
                                                              className="h-3 w-3 text-nhsblue"
                                                              fill="currentColor"
                                                              viewBox="0 0 12 12"
                                                            >
                                                              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                            </svg>
                                                          </span>
                                                        </span>
                                                      </Switch>
                                                    </div>
                                                  );
                                                case "radio":
                                                  return (
                                                    <div className="pb-2">
                                                      <label className="text-sm font-medium text-gray-700">
                                                        {userInput.title}
                                                      </label>
                                                      <p className="mt-0.5 text-sm text-gray-500">
                                                        {userInput.description}
                                                      </p>

                                                      <RadioGroup
                                                        value={
                                                          values[userInput.slug]
                                                        }
                                                        onChange={(value) => {
                                                          const newValues = {
                                                            ...values,
                                                          };
                                                          newValues[
                                                            userInput.slug
                                                          ] = value;

                                                          setValues(newValues);
                                                        }}
                                                        className="mt-4"
                                                      >
                                                        <RadioGroup.Label className="sr-only">
                                                          {userInput.title}
                                                        </RadioGroup.Label>
                                                        <div className="space-y-2">
                                                          {userInput.format!.values!.map(
                                                            (value: any) => (
                                                              <RadioGroup.Option
                                                                key={
                                                                  value.value
                                                                }
                                                                value={
                                                                  value.value
                                                                }
                                                                className={({
                                                                  checked,
                                                                }) =>
                                                                  `border bg-white ${
                                                                    checked
                                                                      ? "border-nhsblue"
                                                                      : "hover:bg-nhslightblue border-gray-200"
                                                                  } relative flex cursor-pointer rounded-lg`
                                                                }
                                                              >
                                                                {({
                                                                  active,
                                                                  checked,
                                                                }) => (
                                                                  <>
                                                                    <div className="flex w-full items-center justify-between px-4">
                                                                      <div className="flex items-center">
                                                                        <div className="text-sm py-4">
                                                                          <RadioGroup.Label
                                                                            as="p"
                                                                            className={`font-medium  ${
                                                                              checked
                                                                                ? "text-nhsblue"
                                                                                : "text-gray-500"
                                                                            }`}
                                                                          >
                                                                            {
                                                                              value.title
                                                                            }
                                                                          </RadioGroup.Label>
                                                                        </div>
                                                                      </div>
                                                                      {checked && (
                                                                        <div className="shrink-0 text-nhsblue">
                                                                          <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 24 24"
                                                                            fill="currentColor"
                                                                            className="w-6 h-6"
                                                                          >
                                                                            <path
                                                                              fillRule="evenodd"
                                                                              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                                                              clipRule="evenodd"
                                                                            />
                                                                          </svg>
                                                                        </div>
                                                                      )}
                                                                    </div>
                                                                  </>
                                                                )}
                                                              </RadioGroup.Option>
                                                            )
                                                          )}
                                                        </div>
                                                      </RadioGroup>
                                                    </div>
                                                  );
                                                default:
                                                  return null;
                                              }
                                            })()}
                                          </div>
                                        );
                                      }
                                    )}
                                    <div className="pb-8 pt-6">
                                      <label className="text-sm font-medium text-gray-700">
                                        {"Regions"}
                                      </label>
                                      <p className="mt-0.5 text-sm text-gray-500">
                                        {"The regions of the body scan"}
                                      </p>

                                      <div className="mt-4">
                                        <div className="space-y-2">
                                          {calculator.regions.map(
                                            (region: any) => (
                                              <>
                                                <input
                                                  type="checkbox"
                                                  id={region.slug}
                                                  name={region.slug}
                                                  key={region.id}
                                                  value={region.slug}
                                                  checked={(
                                                    values[
                                                      "regions"
                                                    ] as string[]
                                                  ).includes(region.slug)}
                                                  onChange={(e) => {
                                                    const val = e.target.value;
                                                    let regions: string[] =
                                                      values[
                                                        "regions"
                                                      ] as string[];
                                                    if (regions.includes(val)) {
                                                      regions = regions.filter(
                                                        (v) => v !== val
                                                      );
                                                      setValues({
                                                        ...values,
                                                        regions,
                                                      });
                                                    } else {
                                                      regions.push(val);
                                                      setValues({
                                                        ...values,
                                                        regions,
                                                      });
                                                    }
                                                  }}
                                                  hidden
                                                  className="hidden"
                                                />
                                                <label
                                                  id={region.slug}
                                                  htmlFor={region.slug}
                                                  className={`border bg-white ${
                                                    (
                                                      values[
                                                        "regions"
                                                      ] as string[]
                                                    ).includes(region.slug)
                                                      ? "border-nhsblue"
                                                      : "hover:bg-nhslightblue border-gray-200"
                                                  } relative flex cursor-pointer rounded-lg`}
                                                >
                                                  <div className="flex w-full items-center justify-between px-4">
                                                    <div className="flex items-center">
                                                      <div className="text-sm py-4">
                                                        <label
                                                          // for={region.slug}
                                                          // as="p"
                                                          className={`font-medium  ${
                                                            (
                                                              values[
                                                                "regions"
                                                              ] as string[]
                                                            ).includes(
                                                              region.slug
                                                            )
                                                              ? "text-nhsblue"
                                                              : "text-gray-500"
                                                          }`}
                                                        >
                                                          {region.name}
                                                        </label>
                                                      </div>
                                                    </div>
                                                    {(
                                                      values[
                                                        "regions"
                                                      ] as string[]
                                                    ).includes(region.slug) && (
                                                      <div className="shrink-0 text-nhsblue">
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 24 24"
                                                          fill="currentColor"
                                                          className="w-6 h-6"
                                                        >
                                                          <path
                                                            fillRule="evenodd"
                                                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                                            clipRule="evenodd"
                                                          />
                                                        </svg>
                                                      </div>
                                                    )}
                                                  </div>
                                                </label>
                                              </>
                                            )
                                          )}
                                        </div>
                                        {errors["regions"] && (
                                          <ErrorMessage
                                            name={"regions"}
                                            component="div"
                                            className="mt-2 text-sm font-medium text-red-500"
                                          />
                                        )}
                                      </div>

                                      {/*<RadioGroup*/}
                                      {/*    value={values.regions}*/}
                                      {/*    onChange={(value) => {*/}
                                      {/*      const newValues = { ...values };*/}
                                      {/*      newValues["regions"] = value;*/}

                                      {/*      setValues(newValues);*/}
                                      {/*    }}*/}
                                      {/*    className="mt-4"*/}
                                      {/*>*/}
                                      {/*  <RadioGroup.Label className="sr-only">*/}
                                      {/*    {"Regions"}*/}
                                      {/*  </RadioGroup.Label>*/}
                                      {/*  <div className="space-y-2">*/}
                                      {/*    {calculator.regions.map((region: any) => (*/}
                                      {/*        <RadioGroup.Option*/}
                                      {/*            key={region.id}*/}
                                      {/*            value={region.slug}*/}
                                      {/*            className={({ checked }) =>*/}
                                      {/*                `border bg-white ${*/}
                                      {/*                    checked*/}
                                      {/*                        ? "border-nhsblue"*/}
                                      {/*                        : "hover:bg-nhslightblue border-gray-200"*/}
                                      {/*                } relative flex cursor-pointer rounded-lg`*/}
                                      {/*            }*/}
                                      {/*        >*/}
                                      {/*          {({ active, checked }) => (*/}
                                      {/*              <>*/}
                                      {/*                <div className="flex w-full items-center justify-between px-4">*/}
                                      {/*                  <div className="flex items-center">*/}
                                      {/*                    <div className="text-sm py-4">*/}
                                      {/*                      <RadioGroup.Label*/}
                                      {/*                          as="p"*/}
                                      {/*                          className={`font-medium  ${*/}
                                      {/*                              checked ? "text-nhsblue" : "text-gray-500"*/}
                                      {/*                          }`}*/}
                                      {/*                      >*/}
                                      {/*                        {region.name}*/}
                                      {/*                      </RadioGroup.Label>*/}
                                      {/*                    </div>*/}
                                      {/*                  </div>*/}
                                      {/*                  {checked && (*/}
                                      {/*                      <div className="shrink-0 text-nhsblue">*/}
                                      {/*                        <svg*/}
                                      {/*                            xmlns="http://www.w3.org/2000/svg"*/}
                                      {/*                            viewBox="0 0 24 24"*/}
                                      {/*                            fill="currentColor"*/}
                                      {/*                            className="w-6 h-6"*/}
                                      {/*                        >*/}
                                      {/*                          <path*/}
                                      {/*                              fillRule="evenodd"*/}
                                      {/*                              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"*/}
                                      {/*                              clipRule="evenodd"*/}
                                      {/*                          />*/}
                                      {/*                        </svg>*/}
                                      {/*                      </div>*/}
                                      {/*                  )}*/}
                                      {/*                </div>*/}
                                      {/*              </>*/}
                                      {/*          )}*/}
                                      {/*        </RadioGroup.Option>*/}
                                      {/*    ))}*/}
                                      {/*  </div>*/}
                                      {/*</RadioGroup>*/}
                                    </div>
                                    <div className="py-6">
                                      <label className="block text-sm font-medium text-gray-700 mb-1.5">
                                        Location
                                      </label>
                                      <Listbox
                                        value={values.location}
                                        onChange={(locationSlug) =>
                                          setValues({
                                            ...values,
                                            location: locationSlug,
                                          })
                                        }
                                      >
                                        <div className="relative mt-1">
                                          <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-gray-200 sm:text-sm">
                                            <span className="block truncate">
                                              {
                                                calculator.locations.find(
                                                  (location: any) =>
                                                    values.location ===
                                                    location.slug
                                                )!.name
                                              }
                                            </span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                              <SelectorIcon
                                                className="h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          </Listbox.Button>
                                          <Transition
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                          >
                                            <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                              {calculator.locations.map(
                                                (location: any) => (
                                                  <Listbox.Option
                                                    key={location.slug}
                                                    className={({ active }) =>
                                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                        active
                                                          ? "bg-green-100 text-green-900"
                                                          : "text-gray-900"
                                                      }`
                                                    }
                                                    value={location.slug}
                                                  >
                                                    {({ selected }) => (
                                                      <>
                                                        <span
                                                          className={`block truncate ${
                                                            selected
                                                              ? "font-medium"
                                                              : "font-normal"
                                                          }`}
                                                        >
                                                          {location.name}
                                                        </span>
                                                        {selected ? (
                                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-600">
                                                            <CheckIcon
                                                              className="h-5 w-5"
                                                              aria-hidden="true"
                                                            />
                                                          </span>
                                                        ) : null}
                                                      </>
                                                    )}
                                                  </Listbox.Option>
                                                )
                                              )}
                                            </Listbox.Options>
                                          </Transition>
                                        </div>
                                      </Listbox>
                                    </div>
                                    <div className="flex justify-end py-6">
                                      <button
                                        type="submit"
                                        disabled={isSubmitting || !isValid}
                                        className={`align-right mt-1 inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-xl shadow-sm text-base font-semibold text-white tracking-tight bg-nhsblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-nhsblue ${
                                          isSubmitting
                                            ? "animate-pulse"
                                            : "hover:bg-nhsdarkblue"
                                        } ${!isValid ? "opacity-50" : ""}`}
                                      >
                                        Calculate
                                      </button>
                                    </div>
                                  </Form>
                                )}
                              </Formik>
                            ) : null}
                          </>
                        )}
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
      <ErrorModal isOpen={isErrorModalOpen} setIsOpen={setIsErrorModalOpen} />
    </>
  );
}
