import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import { Outlet } from "react-router-dom";

import FullscreenLoadingIndicator from "../../visual/shared/FullscreenLoadingIndicator";

export default function RouteAuthenticatorWithAuth0Provider() {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      redirectUri={window.location.protocol + "//" + window.location.host + "/admin"}
      cacheLocation="localstorage"
      useRefreshTokens
      audience={process.env.REACT_APP_AUTH0_AUDIENCE!}
    >
      <RouteAuthenticator />
    </Auth0Provider>
  );
}

const RouteAuthenticator = withAuthenticationRequired(() => <Outlet />, {
  onRedirecting: () => <FullscreenLoadingIndicator />,
});
